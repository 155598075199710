import { UrlConfig } from "./config/config";

//Change environment to production whil sending code to production

const environment = "production";
// const environment = "staging";

export const backend_url = UrlConfig[environment].backend_url;
export const recruiter_web_url = UrlConfig[environment].recruiter_web_url;
export const super_admin_web_url = UrlConfig[environment].super_admin_web_url;
