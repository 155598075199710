import "./App.css";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./Authentication/Login";
import CheckLoginRoute from "./CheckLoginRoute";
import ReactTooltip from "react-tooltip";
import ForgotPassword from "./Authentication/ForgotPassword";
import ResetPassword from "./Authentication/ResetPassword";
import jwtDecode from "jwt-decode";
import { recruiter_web_url, super_admin_web_url } from "./constant";

function App() {
  function getCookie(name) {
    var value = "; " + document.cookie;
    var parts = value.split("; " + name + "=");
    if (parts.length === 2) return parts.pop().split(";").shift();
  }
  const deleteAllCookie = () => {
    let cookies = document.cookie.split("; ");
    for (let c = 0; c < cookies.length; c++) {
      let d = window.location.hostname.split(".");
      while (d.length > 0) {
        let cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path=";
        let p = window.location.pathname.split("/");
        document.cookie = cookieBase + "/";
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/");
          p.pop();
        }
        d.shift();
      }
    }
  };
  const token = getCookie("Xh7ERL0G");

  const redirectTowebSite = () => {
    const decoded = jwtDecode(token);
    const expirationTime = decoded.exp * 1000 - 60000;
    console.log(decoded);

    if (Date.now() <= expirationTime) {
      if (decoded.access === "superadmin") {
        return window.open(super_admin_web_url, "_self");
      }

      if (decoded.access === "recruiter") {
        return window.open(recruiter_web_url, "_self");
        //return window.open("http://localhost:3001/dashboard", "_self")
      }
    } else {
      localStorage.clear();
      deleteAllCookie();
      return <Navigate to="/login" />;
    }
  };

  return (
    <>
      <ReactTooltip />
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              token === null || token === undefined ? (
                <Navigate to="/login" />
              ) : (
                <>{redirectTowebSite()}</>
              )
            }
          />
          <Route
            path="/login"
            element={
              <CheckLoginRoute>
                <Login />
              </CheckLoginRoute>
            }
          />
          <Route
            path="/forgotpassword"
            element={
              <CheckLoginRoute>
                {" "}
                <ForgotPassword />{" "}
              </CheckLoginRoute>
            }
          />
          <Route
            path="/resetpassword/:email"
            element={
              <CheckLoginRoute>
                <ResetPassword />
              </CheckLoginRoute>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
